import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  Flex,
  Box,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { hideNotification } from '../../../store/noficiation/action';

const CustomAlert = ({ visible, type, title, description }) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideNotification());
  };

  useEffect(() => {
    if (visible && type !== 'info') {
      const timer = setTimeout(() => {
        handleClose();
      }, 4000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [visible]);

  return visible ? (
    <Alert
      status={type}
      position="absolute"
      display="flex"
      justifyContent="space-between"
      zIndex={9}
    >
      <Flex alignItems="center">
        <AlertIcon />
        <Box>
          <AlertTitle>{title}</AlertTitle>
          <AlertDescription>{description}</AlertDescription>
        </Box>
      </Flex>
      <CloseButton
        alignSelf="flex-start"
        position="relative"
        right={-1}
        top={-1}
        onClick={handleClose}
      />
    </Alert>
  ) : (
    <></>
  );
};

export { CustomAlert };
