import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import NavBar from '@src/components/NavBar';
import { CustomAlert } from '@shared/components/CustomAlert/CustomAlert';
import { FullscreenLoader } from '@shared/components/FullscreenLoader/FullscreenLoader';
import { useRouter } from 'next/router';
import routes from '@src/utils/routes';
const Layout = ({ children }) => {
  const router = useRouter();
  const { activeNotification, type, title, description } = useSelector(
    //@ts-expect-error: store not typed
    (state) => state.notificationReducer
  );
  const [showNotification, setShowNotification] = useState(false);
  const [loadingScreen] = useState(false);

  useEffect(() => {
    setShowNotification(activeNotification);
  }, [activeNotification]);

  const displayNav = router.pathname !== routes.home;

  return (
    <>
      {displayNav && <NavBar />}
      <CustomAlert
        visible={showNotification}
        type={type}
        title={title}
        description={description}
      />

      {loadingScreen && <FullscreenLoader />}
      <main>{children}</main>
    </>
  );
};

export default Layout;
