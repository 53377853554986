import {
  Flex,
  Image,
  Text,
  MenuList as ChakraMenuList,
  ButtonProps,
  useDisclosure,
  MenuItem,
} from '@chakra-ui/react';
import { iconPath } from '@shared/helpers/config';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import { ComponentType } from 'react';
import LogoutModal from './LogoutModal';
import Link from 'next/link';

const HuggaButton: ComponentType<ButtonProps> = dynamic(() =>
  import('hugga-reactjs-front-library').then(({ HuggaButton: Button }) => Button)
);

const MenuList = () => {
  const { t } = useTranslation();
  const profileLabel = t('profile', { ns: 'common', keyPrefix: 'navmenu' });
  const signoutLabel = t('signout', { ns: 'common', keyPrefix: 'navmenu' });
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <ChakraMenuList
        display="flex"
        flexDirection="column"
        height="168px"
        width="261px"
        justifyContent="space-between"
        padding="16px"
        border="none"
      >
        <Link href="/profile">
          <MenuItem
            w="full"
            bg="transparent"
          >
            <Flex w="full">
              <HuggaButton
                width="100%"
                padding={0}
                height="40px"
              >
                {profileLabel}
              </HuggaButton>
            </Flex>
          </MenuItem>
        </Link>
        <MenuItem
          w="full"
          bg="transparent"
        >
          <Flex
            as="button"
            backgroundColor="gray.50"
            width="100%"
            height="40px"
            borderRadius="4px"
            alignItems="center"
            gap="10px"
            paddingX="12px"
            _hover={{
              backgroundColor: 'gray.100',
            }}
            onClick={onOpen}
          >
            <Image
              src={`${iconPath}powerGray.svg`}
              alt="Logout icon"
            />
            <Text
              fontSize="lg"
              color="gray.700"
            >
              {signoutLabel}
            </Text>
          </Flex>
        </MenuItem>
      </ChakraMenuList>
      <LogoutModal
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
};

export default MenuList;
