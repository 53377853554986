const huggaLogoPath = '/static/images/hugga-logo.svg';
const huggaMiniLogoPath = '/static/images/hugga-mini-logo.svg';
const startingLogosPath = '/static/images/starting/';
const registerLogosPath = '/static/images/register/';
const iconPath = '/static/images/icons/';
const libraryImagesPath = '/static/library-images/';
const pendingProfileSectionsImagesPath = '/static/images/profile/pendingSectionImages/';

export {
  huggaLogoPath,
  huggaMiniLogoPath,
  startingLogosPath,
  registerLogosPath,
  iconPath,
  libraryImagesPath,
  pendingProfileSectionsImagesPath,
};
