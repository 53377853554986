import dynamic from 'next/dynamic';
import { ComponentType, useContext } from 'react';
import { Text, ButtonProps } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import { AuthContext } from '../context';

const Button: ComponentType<ButtonProps> = dynamic(() =>
  import('hugga-reactjs-front-library').then(({ HuggaButton: Button }) => Button)
);

const LogoutButton = () => {
  const { t } = useTranslation();
  const signoutLabel = t('logout', { ns: 'components', keyPrefix: 'logoutModal' });
  const { logOut } = useContext(AuthContext);
  return (
    <Button
      paddingY={0}
      paddingX={3}
      height="40px"
      onClick={logOut}
    >
      <Text
        fontSize="lg"
        color="white"
      >
        {signoutLabel}
      </Text>
    </Button>
  );
};

export default LogoutButton;
