import {
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import LogoutButton from '@features/Auth/components/LogoutButton';
import { HuggaButton } from 'hugga-reactjs-front-library';
import { useTranslation } from 'next-i18next';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const LogoutConfirmationModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation('components');
  const cancel = t('cancel', { keyPrefix: 'logoutModal' });
  const message = t('message', { keyPrefix: 'logoutModal' });
  const title = t('title', { keyPrefix: 'logoutModal' });
  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent maxW="21rem">
        <ModalHeader
          color="gray.700"
          fontSize="lg"
          fontWeight="bold"
        >
          {title}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text
            fontSize="md"
            color="gray.700"
          >
            {message}
          </Text>
        </ModalBody>
        <ModalFooter justifyContent="space-between">
          <HuggaButton
            bg="gray.100"
            paddingY={0}
            paddingX={3}
            height="40px"
            onClick={onClose}
            color="gray.500"
          >
            {cancel}
          </HuggaButton>
          <LogoutButton />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LogoutConfirmationModal;
