import { resizeGoogleImage } from '../utils';
import fetchWrapper from './fetch-wrapper';
import { msalInstance } from '@features/Auth/authConfig';
import { USER_PROFILE } from '@src/constants';

const GOOGLE = 'google.com';
const GOOGLE_PHOTOS = 'https://people.googleapis.com/v1/people/me?personFields=photos';
const IMAGE_URL = 'https://devhuggafrontapp.blob.core.windows.net/images/';

export async function fetchSavePhotoProfile(formdata) {
  try {
    return await fetchWrapper(`${USER_PROFILE}/image`, {
      method: 'PATCH',
      body: formdata,
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
  } catch (e) {
    console.log('Error', e);
  }
}

async function getImageFromGoogle(tokenGoogle) {
  try {
    const response = await fetch(GOOGLE_PHOTOS, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${tokenGoogle}`,
      },
    });
    const account = await response.json();
    return account?.photos ? resizeGoogleImage(account?.photos[0]?.url) : null;
  } catch (e) {
    console.log('Error', e);
    return null;
  }
}

export async function getImageFromIdp(account) {
  if (account?.idTokenClaims?.idp === GOOGLE) {
    return getImageFromGoogle(account?.idTokenClaims?.idp_access_token);
  }
}

export async function getImageProfile() {
  try {
    const user = await fetchWrapper(USER_PROFILE);

    if (user?.image_url == null) {
      const account = msalInstance?.getActiveAccount();
      return { image: await getImageFromIdp(account), from_idp: true };
    }

    return { image: `${IMAGE_URL}${user?.image_url}`, from_idp: false };
  } catch (e) {
    console.log('Error', e);
  }
}
