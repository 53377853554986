import { Flex, Button } from '@chakra-ui/react';
import Image from 'next/image';
import { useIsAuthenticated } from '@azure/msal-react';
import { useTranslation } from 'next-i18next';
import { SearchIcon } from '@chakra-ui/icons';
import logo from '../../../public/static/images/hugga-mini-logo.svg';
import { HuggaButton } from 'hugga-reactjs-front-library';
import Menu from './Menu';
import Link from 'next/link';
import routes from '@utils/routes';

const NavBar = () => {
  const { t } = useTranslation(['common']);
  const exploreLabel = t('search');
  const isAuthed = useIsAuthenticated();
  const registerLabel = t('createProfile');
  return (
    <Flex
      flex={1}
      height="56px"
      background="#FFFFFF"
      alignItems="center"
      justifyContent="center"
      borderBottomColor="gray.200"
      borderBottomWidth={1}
      display="flex"
      py={3}
      px={4}
      position="sticky"
      zIndex={3}
      top={0}
    >
      <Flex
        flex={1}
        maxWidth="1366px"
        marginX={{ base: '5px', lg: '54px' }}
        alignItems="center"
      >
        <Flex
          width="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <Link href="/profile">
            <Image
              height={25}
              src={logo}
              alt="Hugga's Logo"
            />
          </Link>
          <Flex
            alignItems="center"
            gap="12px"
          >
            <Link href="/explore">
              <Button
                variant="outline"
                width="auto"
                height="32px"
                paddingY={0}
                paddingX={3}
                fontSize="sm"
                color="purple.400"
                borderColor="purple.400"
                rightIcon={<SearchIcon />}
              >
                {exploreLabel}
              </Button>
            </Link>
            {isAuthed ? (
              <Menu />
            ) : (
              <Link
                href={routes.home}
                passHref
              >
                <HuggaButton
                  fontSize="14px"
                  py={1}
                  px={3}
                  height={8}
                >
                  {registerLabel}
                </HuggaButton>
              </Link>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default NavBar;
