import axios from 'axios';
import { loginRequest, msalInstance } from './authConfig';

const { scopes } = loginRequest;
const instance = axios.create();

instance.interceptors.request.use(
  async (config) => {
    if (config.headers['Content-Type'] === undefined) {
      config.headers['Content-Type'] = 'application/json';
    }

    const account = msalInstance.getAllAccounts()[0];
    if (account) {
      const accessTokenResponse = await msalInstance.acquireTokenSilent({
        scopes,
        account,
      });

      if (accessTokenResponse) {
        const accessToken = accessTokenResponse.accessToken;
        if (config.headers && accessToken) {
          config.headers['Authorization'] = 'Bearer ' + accessToken;
        }
      }
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      // msalInstance.logoutRedirect();
      // Router.push('/');
    }
  }
);

export default instance;
