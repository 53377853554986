import MenuList from './MenuList';
import { useQuery } from '@tanstack/react-query';
import { getUserAvatar } from '@features/Profile/querys';
import { useIsAuthenticated } from '@azure/msal-react';
import { Menu as ChakraMenu, MenuButton, Button } from '@chakra-ui/react';

const Menu = () => {
  const isAuthed = useIsAuthenticated();
  const { data: userAvatar } = useQuery({
    queryKey: ['userAvatar'],
    queryFn: () => getUserAvatar(),
    enabled: isAuthed,
  });
  return (
    <ChakraMenu
      isLazy
      placement="bottom-end"
      closeOnSelect
      closeOnBlur
    >
      <MenuButton
        as={Button}
        width="32px"
        height="32px"
        boxSizing="border-box"
        borderWidth={1}
        borderColor="purple.500"
        padding={4}
        minW={0}
        backgroundImage={userAvatar ? `url(${userAvatar})` : 'none'}
        backgroundPosition="center"
        backgroundSize="cover"
        _hover={{
          opacity: 0.7,
        }}
        _active={{
          backgroundImage: userAvatar ? `url(${userAvatar})` : 'none',
        }}
      ></MenuButton>
      <MenuList />
    </ChakraMenu>
  );
};

export default Menu;
