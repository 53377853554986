import { SHOW_NOTIFICATION, HIDE_NOTIFICATION } from './type';

export const showNotification = (payload) => (dispatch) => {
  return dispatch({
    type: SHOW_NOTIFICATION,
    payload,
  });
};

export const hideNotification = () => (dispatch) => {
  return dispatch({
    type: HIDE_NOTIFICATION,
  });
};
