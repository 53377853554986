export const base64Encode = (url) => {
  const re = new RegExp('.(gif|jpg|jpeg|tiff|png|ico)$', 'i');
  let name = /[^(/|\\)]*$/.exec(url)[0];
  let type = re.test(name) ? re.exec(name)[0].replace('.', '') : 'jpg';

  return new Promise((resolve, reject) => {
    let image = new Image();

    image.onload = function (event) {
      let canvas = document.createElement('canvas');
      // draw canvas
      canvas.width = image.naturalWidth;
      canvas.height = image.naturalHeight;
      canvas.getContext('2d').drawImage(image, 0, 0);

      let dataUrl = canvas.toDataURL('image/' + type);
      resolve({
        name,
        type,
        dataUrl: dataUrl.split(',')[1],
      });
    };
    image.crossOrigin = 'anonymous';
    image.referrerPolicy = 'no-referrer';
    image.src = url;
  });
};

export const toBlob = (url) => {
  return new Promise((resolve, reject) => {
    base64Encode(url).then((res) => {
      let byteString = atob(res.dataUrl);
      let ab = new ArrayBuffer(byteString.length);
      let ia = new Uint8Array(ab);

      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      // write the array buffer to blob
      let blob = new Blob([ab], { type: 'image/' + res.type });

      let formData = new FormData();
      formData.append('file', blob, res.name);
      resolve(formData.get('file'));
    });
  });
};

export const toFormdata = async (photo) => {
  const file = await toBlob(photo);
  const formdata = new FormData();
  formdata.append('image', file);
  return formdata;
};

export const mimeType = (file) => {
  // https://mimesniff.spec.whatwg.org/#matching-an-image-type-pattern
  var mimes = [
    {
      mime: 'image/jpeg',
      pattern: [0xff, 0xd8, 0xff],
      mask: [0xff, 0xff, 0xff],
    },
    {
      mime: 'image/png',
      pattern: [0x89, 0x50, 0x4e, 0x47],
      mask: [0xff, 0xff, 0xff, 0xff],
    },
    {
      mime: 'application/pdf',
      pattern: [0x25, 0x50, 0x44, 0x46],
      mask: [0xff, 0xff, 0xff, 0xff],
    },
  ];

  const check = (bytes, mime) => {
    for (var i = 0, l = mime.mask.length; i < l; ++i) {
      if ((bytes[i] & mime.mask[i]) - mime.pattern[i] !== 0) {
        return false;
      }
    }
    return true;
  };

  return new Promise((resolve, reject) => {
    try {
      var blob = file?.slice(0, 4);
      var reader = new FileReader();
      reader.onloadend = function (e) {
        if (e.target.readyState === FileReader.DONE) {
          var bytes = new Uint8Array(e.target.result);

          for (var i = 0, l = mimes.length; i < l; ++i) {
            if (check(bytes, mimes[i])) resolve(mimes[i].mime);
          }
          resolve('unknown');
        }
      };
      reader.readAsArrayBuffer(blob);
    } catch (e) {
      reject(e);
    }
  });
};

export const imageSize = (image) => {
  return new Promise((resolve, reject) => {
    try {
      const fileReader = new FileReader();

      fileReader.onload = () => {
        const img = new Image();

        img.onload = () => {
          resolve({ width: img.width, height: img.height });
        };

        img.src = fileReader.result;
      };

      fileReader.readAsDataURL(image);
    } catch (e) {
      reject(e);
    }
  });
};

export const isValidImage = async (image) => {
  const mime = image ? await mimeType(image): '';
  if (mime.match(/\/(jpeg|png)$/)) {
    // const size = await imageSize(image);
    // return size.width == 250 && size.height == 350 && image.size <= 5000000;
    return image.size <= 5000000;
  }
  return false;
};

export const isValidPdf = async (pdf) => {
  const mime = pdf ? await mimeType(pdf) : '';
  if (mime.match(/^application\/pdf$/i)) {
    return pdf.size <= 25000000;
  }
  return false;
};

export const resizeGoogleImage = (url, size = 400) => {
  return url.replace(/=s\d+/, `=s${size}`);
};
