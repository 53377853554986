import {
  USERS,
  SKILLS_USER,
  USER_PROFILE,
  SURVEYS,
  PERSONALITIES_RESULTS,
  PERSONALITY_RESULT,
  BLOB_IMAGES,
} from '@src/constants';
import privateClient from '@features/Auth/axios';
import { GraphProfile, UserProfile } from '@utils/types';
import { msalInstance } from '@features/Auth/authConfig';
import { getImageFromIdp } from '@src/repository/profile';
import { updateUserPhoto } from '@features/UserInfo/mutations';

export const getUserProfile = async (): Promise<UserProfile> => {
  try {
    const res = await privateClient.get(USER_PROFILE);
    return res.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getUserById = async (id: string): Promise<UserProfile> => {
  try {
    const res = await privateClient.get(`${USERS}${id}`);
    return res.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getUserByUuid = async (uuid: string): Promise<GraphProfile> => {
  try {
    const res = await privateClient.get(`${SKILLS_USER}${uuid}`);
    return res.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const getSurveyResult = async ({ queryKey }) => {
  try {
    const [handle] = queryKey;
    const account = msalInstance?.getActiveAccount();
    const res = await privateClient.get(
      `${SURVEYS}/users/${account?.idTokenClaims?.oid}/results/${handle}`
    );
    return res.data;
  } catch (error) {
    return null;
  }
};

export const getPersonalitiesResult = async ({ queryKey }) => {
  try {
    const [handle] = queryKey;
    const res = await privateClient.get(`${PERSONALITIES_RESULTS}/${handle.replace('_key1', '')}`);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getPersonalityResult = async ({ queryKey }) => {
  try {
    const [handle] = queryKey;
    const res = await privateClient.get(`${PERSONALITY_RESULT}/${handle.replace('_key2', '')}`);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getUserAvatar = async (update: boolean = false): Promise<string | null> => {
  try {
    const user = await privateClient.get(USER_PROFILE);
    const imageUrl = user?.data?.image_url;
    if (imageUrl) {
      return `${BLOB_IMAGES}${imageUrl}`;
    }
    const account = msalInstance?.getActiveAccount();
    const avatarFromIdp: string | null = await getImageFromIdp(account);
    if (update) {
      const updated = await updateUserPhoto(avatarFromIdp);
      return `${BLOB_IMAGES}${updated?.data?.image_name}`;
    }
    return avatarFromIdp;
  } catch (e) {
    console.error(e);
    return null;
  }
};
