import privateClient from '@features/Auth/axios';
import { USER_CAREER, USER_HANDLE, USER_PROFILE, USER_ROLE } from '@src/constants';
import type { Role } from './types';
import { toFormdata } from '@src/utils';

export const updateUserRole = async (role: Role, company: string) => {
  try {
    const updatedProfile = await privateClient.patch(USER_ROLE, {
      company,
      role_id: role.id,
      role_title: role.title,
    });
    if (updatedProfile) {
      return updatedProfile;
    }
    throw new Error('Error updating user role');
  } catch (error) {
    throw new Error(error);
  }
};

export const updateUserStudies = async (education: string, institution: string) => {
  try {
    const updatedProfile = await privateClient.patch(USER_CAREER, {
      career: education,
      place: institution,
    });
    if (updatedProfile) {
      return updatedProfile;
    }
    throw new Error('Error updating user studies');
  } catch (error) {
    throw new Error(error);
  }
};

export const updateUserHandle = async (handle: string) => {
  try {
    const updatedProfile = await privateClient.patch(`${USER_HANDLE}/${handle}`);
    if (updatedProfile) {
      return updatedProfile;
    }
    throw new Error('Error updating user handle');
  } catch (error) {
    throw new Error(error);
  }
};

export const updateUserProfile = async (params: any) => {
  try {
    const updatedProfile = await privateClient.put(USER_PROFILE, params);
    if (updatedProfile) {
      return updatedProfile;
    }
    throw new Error('Error updating user profile');
  } catch (error) {
    throw new Error(error);
  }
};

export const updateUserPhoto = async (image: any) => {
  try {
    const formdata = await toFormdata(image);
    const updatedPhoto = await privateClient.patch(`${USER_PROFILE}/image`, formdata, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (updatedPhoto) {
      return updatedPhoto;
    }
    throw new Error('Error updating user avatar');
  } catch (error) {
    throw new Error(error);
  }
};

export const uploadResume = async (file: any) => {
  try {
    const formdata = new FormData();
    formdata.append('resume', file);
    const uploaded = await privateClient.patch(`${USER_PROFILE}/resume`, formdata, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (uploaded) {
      return uploaded;
    }
    throw new Error('Oops something went wrong, try again');
  } catch (error) {
    throw new Error(error);
  }
};

export const removeResume = async () => {
  try {
    const removed = await privateClient.delete(`${USER_PROFILE}/resume`);
    return removed;
  } catch (error) {
    throw new Error(error);
  }
};
